import { ReactNode } from 'react';
import { Box, Stack, Typography } from 'src/components/mui-components';

interface SystemAdminContentWrapperProps {
  header: string;
  description?: string;
  children?: ReactNode;
}

export const SystemAdminContentWrapper = ({
  header,
  description,
  children,
}: SystemAdminContentWrapperProps) => (
  <Box sx={{ py: 4, pr: 6, pl: 4 }}>
    <Stack gap={3}>
      <Stack>
        <Typography variant="h1" component="h2">
          {header}
        </Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Stack>
      {children}
    </Stack>
  </Box>
);
